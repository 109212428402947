<script>
import Chart from 'chart.js/auto';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    const chart = new Chart(this.$el, {
      type: this.type,
      data: this.data,
      options: this.options,
    });

    this.$on('hook:destroyed', () => chart.destroy());
  },
};
</script>

<template>
  <canvas />
</template>
