<script>
export default {
  props: {
    titleTag: {
      type: [String, Object],
      default: 'h3',
    },
    tooltipOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    tooltipOptionsMerged() {
      return {
        multilined: true,
        position: 'is-left',
        ...this.tooltipOptions,
      };
    },
  },
};
</script>

<template>
  <div class="box pl-3 pr-2 py-3 flex-between-column">
    <header class="is-flex is-justify-content-space-between is-align-items-center mb-3">
      <component :is="titleTag" class="is-size-8">
        <slot name="title" />
      </component>

      <b-tooltip
        v-if="$slots.tooltipContent"
        v-bind="tooltipOptionsMerged"
      >
        <template #content>
          <slot name="tooltipContent" />
        </template>
        <b-icon class="opacity-40 is-size-8" icon="info" />
      </b-tooltip>
    </header>

    <div>
      <p class="is-size-6">
        <slot />
      </p>

      <footer v-if="$slots.footer" class="pt-3 mt-2 btop-grey">
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>
