<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [null, null],
    },
    titleTag: {
      type: [String, Object],
      default: 'h1',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    initialSelector: {
      type: String,
      default: 'TODAY',
    },
  },
  data() {
    return {
      selector: this.initialSelector,
      dates: this.value,
    };
  },
  methods: {
    onSelectorChange() {
      let begin = this.$moment();
      let end = this.$moment();

      switch (this.selector) {
        case 'YESTERDAY':
          begin = begin.subtract(1, 'day');
          end = end.subtract(1, 'day');
          break;
        case 'LAST_7_DAYS':
          begin = begin.subtract(7, 'days');
          end = end.subtract(1, 'day');
          break;
        case 'LAST_30_DAYS':
          begin = begin.subtract(30, 'days');
          end = end.subtract(1, 'day');
          break;
        case 'LAST_WEEK':
          begin = begin.subtract(1, 'week').startOf('week');
          end = end.subtract(1, 'week').endOf('week');
          break;
        case 'LAST_MONTH':
          begin = begin.subtract(1, 'month').startOf('month');
          end = end.subtract(1, 'month').endOf('month');
          break;
        case 'LAST_3_MONTH':
          begin = begin.subtract(3, 'month').add(1, 'day');
          break;
        case 'LAST_6_MONTH':
          begin = begin.subtract(6, 'month').add(1, 'day');
          break;
        case 'LAST_12_MONTH':
          begin = begin.subtract(12, 'month').add(1, 'day');
          break;
        case 'ALL':
          begin = begin.subtract(10, 'year').startOf('year');
          break;

        default:
      }

      this.dates = [begin.toDate(), end.toDate()];
      this.$emit('input', this.dates);
    },
    onDateChange() {
      this.selector = 'CUSTOM';
      this.$emit('input', this.dates);
    },
  },
  created() {
    this.onSelectorChange();
  },
};
</script>

<template>
  <section>
    <component v-if="$slots.title" :is="titleTag" class="title">
      <slot name="title" />
    </component>
    <form class="columns">
      <div class="column is-narrow">
        <b-datepicker
          v-model="dates"
          :disabled="isLoading"
          :mobile-native="false"
          range
          @input="onDateChange"
        />
      </div>
      <div class="column is-narrow">
        <b-select v-model="selector" @input="onSelectorChange">
          <option v-show="selector == 'CUSTOM'" value="CUSTOM">Personnalisé</option>
          <option value="TODAY">Aujourd'hui</option>
          <option value="YESTERDAY">Hier</option>
          <option value="LAST_7_DAYS">7 derniers jours</option>
          <option value="LAST_30_DAYS">30 derniers jours</option>
          <option value="LAST_WEEK">semaine dernière</option>
          <option value="LAST_MONTH">mois dernier</option>
          <option value="LAST_3_MONTH">3 derniers mois</option>
          <option value="LAST_6_MONTH">6 derniers mois</option>
          <option value="LAST_12_MONTH">12 derniers mois</option>
          <option value="ALL">Tout (depuis le début)</option>
        </b-select>
      </div>
    </form>
    <slot />
  </section>
</template>
